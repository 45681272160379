/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
    Box,
    Button,
    FormHelperText,
    TextField,
    Link,
    CircularProgress,
    InputLabel,
    FormControl,
    OutlinedInput,
    IconButton,
    Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useAuth from "src/hooks/useAuth";
import useRefMounted from "src/hooks/useRefMounted";
import { useTranslation } from "react-i18next";
import GoogleAuthButton from "../../../../components/Buttons/GoogleAuthButton";
import LinkedinAuthButton from "src/components/Buttons/LinkedinAuthButton";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const LoginJWT = ({ setThirdPartySignInWindow, closeAllOpenedChildWindows, isSubComponent = false }) => {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const { mrkt365login, googleInitialize, linkedinInitialize } = useAuth();
    const isMountedRef = useRefMounted();
    const { t } = useTranslation();
    const [toShowPassword, setToShowPassword] = useState(false);
    const navigate = useNavigate();
    const handleClickShowPassword = () => {
        setToShowPassword(!toShowPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleGoogleAuth = async () => {
        if (isSubComponent) closeAllOpenedChildWindows();
        googleInitialize(setThirdPartySignInWindow);
    };
    const handleLinkedInAuth = async () => {
        if (isSubComponent) closeAllOpenedChildWindows();
        linkedinInitialize(setThirdPartySignInWindow)
    }
    const handleGoToForgotPassword = () => {
        closeAllOpenedChildWindows();
        navigate("/forgot-password");
    };

    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
                terms: true,
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email(t("The email provided should be a valid email address"))
                    .max(255)
                    .required(t("The email field is required")),
                password: Yup.string().max(255).required(t("The password field is required")),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    closeAllOpenedChildWindows();
                    Cookies.set("email", values.email);
                    var loginFailed = await mrkt365login(values.email, values.password);

                    if (loginFailed === true) {
                        setErrors({ terms: "Error logging in, please verify your credentials" });
                    } else {
                        if (isSubComponent) navigate(0);
                    }

                    if (isMountedRef.current) {
                        setStatus({ success: false });
                        setSubmitting(false);
                    }
                } catch (err) {
                    setErrors({ terms: "Error logging in, please verify your credentials" });
                    if (isMountedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            margin: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "@media screen and (max-width: 767px)": { display: "block", }
                        }}
                    >
                        <GoogleAuthButton buttonText="Google" customOnClick={handleGoogleAuth} />
                        <LinkedinAuthButton customOnClick={handleLinkedInAuth} buttonText="LinkedIn" />
                    </Box>

                    <Box textAlign="center">
                        <div style={{ borderBottom: "1px solid #ccc", marginBottom: "40px", marginTop: "40px", position: "relative" }}>
                            <span style={{ position: "absolute", top: "-20px", color: "rgba(34, 51, 84, 0.5)", lineHeight: "30px", fontSize: "16px", fontWeight: "700", width: "42px", height: "40px", background: "#F6F8FB", padding: "5px", left: "0px", right: "0", margin: "auto", }}>OR</span>
                        </div>
                        <Typography
                            variant="h4"
                            color="text.secondary"
                            fontWeight="normal"
                            sx={{
                                mb: 1,
                            }}
                        >
                            {t("Fill in the fields below to sign into your account.")}
                        </Typography>
                    </Box>
                    <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        margin="normal"
                        helperText={touched.email && errors.email}
                        label={t("Email address")}
                        name="email"
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    <FormControl sx={{ width: "100%", mt: 1, mb: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={toShowPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange}
                            error={Boolean(touched.email && errors.email)}
                            name="password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {toShowPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            required
                        />
                        {touched.password && errors.password ? (
                            <FormHelperText error>{errors.password}</FormHelperText>
                        ) : null}
                    </FormControl>
                    {!isSubComponent && (
                        <Box alignItems="center" display="flex" justifyContent="end" sx={{ "@media screen and (max-width: 767px)": { display: "flex", flexDirection: "column-reverse", } }}>
                          
                            <Link onClick={handleGoToForgotPassword} sx={{ "@media screen and (max-width: 767px)": { display: "block", textAlign: "right", width: "100%", } }}>
                                <b>{t("Lost password?")}</b>
                            </Link>
                        </Box>
                    )}
                    {Boolean(touched.terms && errors.terms) && <FormHelperText error>{errors.terms}</FormHelperText>}

                    <Button
                        sx={{
                            mt: 1,
                        }}
                        color="primary"
                        startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                        disabled={isSubmitting}
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                    >
                        {t("Sign in")}
                    </Button>
                </form>
            )}
        </Formik>
    );
};

export default LoginJWT;
