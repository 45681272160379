import React, { useState, useEffect, useContext } from "react";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { Button } from "@mui/material";
import { projectWebSocketEndpoint } from "src/mrkt365config";
import useAuth from "src/hooks/useAuth";
import AuthContext from "src/contexts/JWTAuthContext";
import VideoCallScreen from "./videoCall";
import IncomingAndOutgoingCallCard from "src/components/Calls/IncomingAndOutgoingCallCard";
import MeetingCall from "src/components/Calls/MeetingCall";
import store from "src/store";

const VideoCallManagement = () => {
    const [otherUserId, setOtherUserId] = useState();
    const [otherUserName, setOtherUserName] = useState();
    const [otherUserAvatarUrl, setOtherUserAvatarUrl] = useState();
    const [callStatus, setCallStatus] = useState({});
    const [showVideoCall, setShowVideoCall] = useState(false);
    const [connected, setConnected] = useState(false);
    const [isCallIncoming, setIsCallIncoming] = useState(false);
    const [isCallOutgoing, setIsCallOutgoing] = useState(false);
    const [isCurrentUserAudioOn, setIsCurrentUserAudioOn] = useState(false);
    const [isCurrentUserVideoOn, setIsCurrentUserVideoOn] = useState(false);
    const [isOtherUserAudioOn, setIsOtherUserAudioOn] = useState(true);
    const [isOtherUserVideoOn, setIsOtherUserVideoOn] = useState(true);

    const { user, basic_info } = useAuth();
    const userId = user?.id;

    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
        projectWebSocketEndpoint + "call_status/" + user?.id,
        {
            share: true,
            shouldReconnect: () => true,
        }
    );

    const handleOtherUserIdChange = (ev) => {
        setOtherUserId(ev.target.value);
    };

    const rejectCall = () => {
        sendMessage(JSON.stringify({ action: "reject_call", other_user_id: otherUserId, user_id: userId }));
        setCallStatus({ status: null });
        setShowVideoCall(false);
    };

    const approveCall = () => {
        sendMessage(JSON.stringify({ action: "approve_call", other_user_id: otherUserId, user_id: userId }));
        setCallStatus({ status: "ongoing_call", other_user_id: otherUserId });
    };

    const handlePlaceCall = (targetUserId) => {
        setCallStatus({ status: "outgoing_call_placed", other_user_id: targetUserId });
        sendMessage(
            JSON.stringify({
                action: "place_call",
                other_user_id: targetUserId,
                user_id: userId,
                caller_profile_pic_url: basic_info.profile_pic,
                caller_name: `${user.first_name} ${user.last_name}`,
            })
        );
    };

    useEffect(() => {
        if (!lastMessage) return;
        console.log(lastMessage);
        const message = JSON.parse(lastMessage.data);
        console.log(message);
        if (message["action"]) {
            switch (message["action"]) {
                case "approve_call": {
                    setCallStatus({ status: "ongoing_call_initiator", other_user_id: otherUserId });
                    setShowVideoCall(true);
                    break;
                }
                case "place_call": {
                    setCallStatus({ status: "incoming_call", other_user_id: message["user_id"] });
                    setOtherUserId(message["user_id"]);
                    setOtherUserName(message["caller_name"]);
                    setOtherUserAvatarUrl(message["caller_profile_pic_url"]);
                    setShowVideoCall(true);
                    break
                }
                case "reject_call": {
                    setCallStatus({ status: null });
                    setShowVideoCall(false);
                    break
                }
                case "end_call": {
                    setCallStatus({ status: "end_call" });
                    // setShowVideoCall(false)
                    break
                }
                default: {
                    break
                }
            }
        }
    }, [lastMessage]);

    const handleStoreStateChange = () => {
        var newStateValue = store.getState();
        newStateValue = newStateValue.meetingCall;

        console.log("new changed state is : ", newStateValue);

        if (newStateValue.newOutgoingCallStartActionTaken === true) {
            setOtherUserId(newStateValue.otherUserId);
            setOtherUserName(newStateValue.otherUserName);
            setOtherUserAvatarUrl(newStateValue.otherUserAvatarUrl);
            handlePlaceCall(newStateValue.otherUserId);
            setIsCallIncoming(false);
            setIsCallOutgoing(true);
        }
    };

    useEffect(() => {
        console.log("started listening to store state change");
        store.subscribe(() => {
            console.log("caught global state change");
            handleStoreStateChange();
        });
    }, []);

    const leaveCurrentMeeting = (end = false) => {
        setShowVideoCall(false);
        setCallStatus({ status: null });
        if (!end) sendMessage(JSON.stringify({ action: "end_call", other_user_id: otherUserId, user_id: userId }))
    };

    return (
        <div>
            {showVideoCall &&
                callStatus &&
                (callStatus.status === "ongoing_call_initiator" || callStatus.status === "ongoing_call") ? (
                <MeetingCall
                    callerDetails={{
                        avatar: otherUserAvatarUrl,
                        name: otherUserName,
                    }}
                    leaveCurrentMeeting={leaveCurrentMeeting}
                    callStatus={callStatus}
                    otherUserId={otherUserId}
                    userId={userId}
                    connected={connected}
                    setConnected={setConnected}
                    isCurrentUserAudioOn={isCurrentUserAudioOn}
                    isCurrentUserVideoOn={isCurrentUserVideoOn}
                    isOtherUserAudioOn={isOtherUserAudioOn}
                    isOtherUserVideoOn={isOtherUserVideoOn}
                />
            ) : null}
            <br />

            {callStatus.status === "incoming_call" ? (
                <IncomingAndOutgoingCallCard
                    isIncomingCall={true}
                    callerDetails={{
                        avatar: otherUserAvatarUrl,
                        name: otherUserName,
                    }}
                    acceptCall={approveCall}
                    rejectCall={rejectCall}
                />
            ) : null}
            {callStatus.status === "outgoing_call_placed" ? (
                <IncomingAndOutgoingCallCard
                    isIncomingCall={false}
                    callerDetails={{
                        avatar: otherUserAvatarUrl,
                        name: otherUserName,
                    }}
                    acceptCall={approveCall}
                    rejectCall={rejectCall}
                />
            ) : null}
        </div>
    );
};

export default VideoCallManagement;
