/* eslint-disable no-param-reassign */
function getCookie(name_of_cookie) {
    // var date1 = new Date();
    var name = name_of_cookie + "=";
    var cookies = document.cookie;
    var ca = cookies.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            // var date2 = new Date();
            var cookie_value = c.substring(name.length, c.length);
            if (cookie_value === "true") {
                return true;
            } else if (cookie_value === "false") {
                return false;
            } else {
                return cookie_value;
            }
        }
    }
    return null;
}

function setCookie(name_of_cookie, value_of_cookie, age_in_number_of_seconds = null) {
    // var name = name_of_cookie + "=";
    if (age_in_number_of_seconds == null) {
        age_in_number_of_seconds = 60 * 60 * 24 * 365 * 20;
    }
    document.cookie = `${name_of_cookie}=${value_of_cookie}; max-age=${age_in_number_of_seconds}; path=/;`;
}

export { getCookie, setCookie };
