import React, { useState, useContext } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { SidebarContext } from "src/contexts/SidebarContext";
import PropTypes from "prop-types";
import { Button, Tooltip, Badge, Collapse, ListItem, styled, tooltipClasses } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandLessTwoToneIcon from "@mui/icons-material/ExpandLessTwoTone";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";

const TooltipWrapper = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.colors.alpha.black[100],
            color: theme.palette.getContrastText(theme.colors.alpha.black[100]),
            fontSize: theme.typography.pxToRem(12),
            fontWeight: "bold",
            borderRadius: theme.general.borderRadiusSm,
            boxShadow: "0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)",
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.colors.alpha.black[100],
        },
    })
);

const SidebarMenuItem = ({
    children,
    link,
    icon: Icon,
    badge,
    badgeTooltip,
    open: openParent,
    active,
    name,
    id,
    ...rest
}) => {
    const [menuToggle, setMenuToggle] = useState(openParent);
    const { t } = useTranslation();
    const { closeSidebar } = useContext(SidebarContext);

    const toggleMenu = () => {
        setMenuToggle((Open) => !Open);
    };

    if (children) {
        return (
            <ListItem component="div" className="Mui-children" key={name} {...rest}>
                <Button
                    disableRipple
                    className={clsx({ "Mui-active": menuToggle })}
                    startIcon={Icon && <Icon />}
                    endIcon={menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />}
                    onClick={toggleMenu}
                >
                    {badgeTooltip ? (
                        <TooltipWrapper title={badgeTooltip} arrow placement="right">
                            {badge === "" ? <Badge color="primary" variant="dot" /> : <Badge badgeContent={badge} />}
                        </TooltipWrapper>
                    ) : badge === "" ? (
                        <Badge color="primary" variant="dot" />
                    ) : (
                        <Badge badgeContent={badge} />
                    )}
                    {t(name)}
                </Button>
                <Collapse in={menuToggle}>{children}</Collapse>
            </ListItem>
        );
    }

    if (link === "https://affiliate.mrkt365.com/affiliates/signup.php#SignupForm") {
        return (
            <ListItem component="div" key={name} {...rest}>
                <Button
                    disableRipple
                    onClick={closeSidebar}
                    href="https://affiliate.mrkt365.com/affiliates/signup.php#SignupForm"
                    startIcon={Icon && <Icon />}
                >
                    {t(name)}
                    {badgeTooltip ? (
                        <TooltipWrapper title={badgeTooltip} arrow placement="right">
                            {badge === "" ? <Badge color="primary" variant="dot" /> : <Badge badgeContent={badge} />}
                        </TooltipWrapper>
                    ) : badge === "" ? (
                        <Badge color="primary" variant="dot" />
                    ) : (
                        <Badge badgeContent={badge} />
                    )}
                </Button>
            </ListItem>
        );
    }

    return (
        <ListItem component="div" key={name} {...rest} id={id ? id : "false"}>
            <Button disableRipple component={RouterLink} onClick={closeSidebar} to={link} startIcon={Icon && <Icon />}>
                {t(name)}
                {badgeTooltip ? (
                    <TooltipWrapper title={badgeTooltip} arrow placement="right">
                        {badge === "" ? <Badge color="primary" variant="dot" /> : <Badge badgeContent={badge} />}
                    </TooltipWrapper>
                ) : badge === "" ? (
                    <Badge color="primary" variant="dot" />
                ) : (
                    <Badge badgeContent={badge} />
                )}
            </Button>
        </ListItem>
    );
};

SidebarMenuItem.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    link: PropTypes.string,
    icon: PropTypes.elementType,
    badge: PropTypes.string,
    badgeTooltip: PropTypes.string,
    open: PropTypes.bool,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
};

SidebarMenuItem.defaultProps = {
    open: false,
    active: false,
};

export default SidebarMenuItem;
