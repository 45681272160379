import axios from 'axios';
import { projectEndpoint, projectV2Endpoint, vendorEndpoint } from 'src/mrkt365config';

class JobService {
	static async getCreatedJobs() {
		var data = {};
		await axios.get(projectV2Endpoint + 'jobs/created').then((res) => {
			data = res.data;
		});
		return data;
	}
	static async createJobFromTask(data) {
		var newJobId;
		await axios.post(projectV2Endpoint + 'create_job_from_task', data)
			.then((res) => (newJobId = res.data.new_job_id))
			.catch((err) => {
				const detailSplitted = err.response.data.detail.split("existing job id: ")
				if(detailSplitted.length > 1 && !isNaN(detailSplitted[1])){
					const partWithJobId = detailSplitted[1]
					const numberREGEX = /\d+/g;
					const possibleJobIdNbrs = partWithJobId.match(numberREGEX)
					window.location.pathname = '/view-job/' + possibleJobIdNbrs[0]
				}
			});
		return newJobId;
	}
	static async getAllJobs(filter) {
		var params_to_send = {
			keyword: filter.search_query,
		};
		var data = [];
		await axios
			.get(vendorEndpoint + 'jobs', {
				params: params_to_send,
			})
			.then((res) => {
				data = res.data;
			});
		return data;
	}

	static async getRecommendedJobs() {
		var data = [];
		await axios.get(vendorEndpoint + 'jobs/recommendations').then((res) => {
			data = res.data;
		});
		return data;
	}

	static async getApplicantJobsDashboardData() {
		var data = {};
		await axios.get(projectV2Endpoint + 'jobs/applicant_dashboard').then((res) => (data = res.data));
		return data;
	}

	static async getMostSkillsMatchedUsers(skills, logged_in_user_id = null) {
		var data = [];
		await axios
			// .get(vendorEndpoint + "get_most_skills_matched_users", {
			.get(vendorEndpoint + 'get_most_skills_matched_users', {
				params: {
					skill_ids: skills && skills.toString(),
					logged_in_user_id: logged_in_user_id,
				},
			})
			.then((res) => {
				data = res.data;
			});
		// var starttime = new Date().getTime();
		return data;
	}

	static async getCreatorJobsDashboard() {
		var data = {};
		await axios.get(projectV2Endpoint + 'jobs/creator_dashboard').then((res) => (data = res.data));
		return data;
	}

	static async getSearchResultsForJobs(filter, isApplicant, isInOpenSearchMode) {
		var params_to_send = {};
		var skills_id_list = [];
		var str_skills_id_list = '';

		if (filter.search_query) {
			params_to_send.keyword = filter.search_query;
		}

		if (filter.per_hour_cost_lower !== null && filter.per_hour_cost_lower) {
			params_to_send.min_cost = filter.per_hour_cost_lower;
		}

		if (filter.per_hour_cost_upper !== null && filter.per_hour_cost_upper) {
			params_to_send.max_cost = filter.per_hour_cost_upper;
		}

		if (filter.skills_id !== '' && filter.skills_id) {
			skills_id_list = filter.skills_id;
			if (skills_id_list.length > 0) {
				for (var i = 0; i < skills_id_list.length; i++) {
					str_skills_id_list += 'skills_id=' + skills_id_list[i];
					if (i !== skills_id_list.length - 1) {
						str_skills_id_list += '&';
					}
				}
			}
		}

		if (filter.date_range_lower !== null && filter.date_range_lower) {
			params_to_send.date_range_lower = `${filter.date_range_lower.getFullYear()}-${filter.date_range_lower.getMonth() + 1
				}-${filter.date_range_lower.getDate()}`;
		}

		if (filter.date_range_upper !== null && filter.date_range_upper) {
			params_to_send.date_range_upper = `${filter.date_range_upper.getFullYear()}-${filter.date_range_upper.getMonth() + 1
				}-${filter.date_range_upper.getDate()}`;
		}

		if (filter.billing_cycle) {
			params_to_send.billing_cycle = `${filter.billing_cycle}`;
		}

		if (filter.job_id) params_to_send.job_id = filter.job_id;

		var list_of_result_jobs = [];

		var endpoint_to_hit = '';
		if (isInOpenSearchMode) endpoint_to_hit = 'filter_jobs_public';
		else if (isApplicant) endpoint_to_hit = 'filter_jobs';
		else endpoint_to_hit = 'jobs/created';

		const api_endpoint_to_fetch_data = projectV2Endpoint + endpoint_to_hit + '?' + str_skills_id_list;

		await axios
			.get(api_endpoint_to_fetch_data, { params: params_to_send })
			.then((res) => {
				if (res) {
					if (res.data) {
						list_of_result_jobs = res.data;
					}
				}
			})
			.catch((error) => {
			});
		return list_of_result_jobs;
	}

	static async applyToJob(job_id) {
		const headerOptions = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		await axios.post(vendorEndpoint + 'jobs/apply/' + job_id, headerOptions).then((res) => {
		});
	}

	static async getAllAppliedJobs() {
		var data = [];
		await axios.get(vendorEndpoint + 'jobs/get_all_applied_jobs').then((res) => {
			data = res.data;
		});
		return data;
	}

	static async getJobApplicationStatus(job_id) {
		var data = [];
		await axios.get(vendorEndpoint + 'jobs/get_job_application_status/' + job_id).then((res) => {
			data = res.data;
		});
		return data.application_status;
	}

	static async getJobApplications(job_id) {
		var data = [];
		await axios.get(vendorEndpoint + 'jobs/' + job_id + '/applications').then((res) => {
			data = res.data;
		});
		return data;
	}

	static async getJob(job_id) {
		var job = null;
		await axios.get(projectV2Endpoint + 'job/' + job_id).then((res) => {
			job = res.data;
		});
		return { job: job };
	}

	static async getJobWithProposals(job_id) {
		var data = [];
		await axios.get(projectV2Endpoint + 'job/job_and_proposals/' + job_id).then((res) => {
			data = res.data;
		});
		return data;
	}

	static async hireApplicant(application_id, data) {
		const headerOptions = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		await axios
			.post(projectEndpoint + 'jobs/applications/' + application_id + '/hire', data, headerOptions)
			.then((res) => {

			});
	}

	static async saveEditedDataOfJob(job_id, data) {
		const headerOptions = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		var is_editing_job_successfull = false;
		await axios
			.post(projectEndpoint + 'business/jobs/' + job_id + '/', data, headerOptions)
			.then((res) => {
				if (res.status === 200) {
					is_editing_job_successfull = true;
				}
			})
			.catch((error) => {
			});
		return is_editing_job_successfull;
	}

	static async deleteJob(job_id) {
		const headerOptions = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		var is_job_deleted_successfully = false;

		await axios
			.delete(projectEndpoint + 'business/jobs/' + job_id, headerOptions)
			.then((res) => {
				if (res.status === 200) {
					is_job_deleted_successfully = true;
				}
			})
			.catch((error) => {

			});

		return is_job_deleted_successfully;
	}

	static async reportJobsViewed(list_of_newly_viewed_job_ids) {
		const headerOptions = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		await axios
			.post(projectEndpoint + 'jobs/views/', list_of_newly_viewed_job_ids, headerOptions)
			.then((res) => {
			})
			.catch((error) => {
			});
	}

	static async updateHourlyJobPrices(jobId, lowerRate, upperRate) {
		await axios
			.patch(projectV2Endpoint + `jobs/update_hourly_prices/${jobId}?lower_price=${lowerRate}&upper_price=${upperRate}`)
			.then((res) => console.log(res));
	}

	static async createReplaceInterViewQuestions(jobId, listOfQuestions) {
		await axios
			.post(projectV2Endpoint + 'jobs/create-replace-interview-questions/' + jobId, listOfQuestions)
			.then((res) => console.log(res));
	}

	static async publishJob(jobId) {
		var newJobId;
		await axios.post(projectV2Endpoint + 'jobs/publish-job/' + jobId).then((res) => (newJobId = res.data.new_job_id));
		return newJobId;
	}

	static async getInterviewQuestions(job_id) {
		try {
			const res = await axios.get(projectV2Endpoint + 'jobs/get-interview-questions/' + job_id);
			// Check if the request was successful
			if (res.data.success) {
				return res.data.questions;
			} else {
				// The server responded, but indicated a failure
				return [];
			}
		} catch (err) {
			// There was an error with the request
			console.error(err);
			return {
				success: false,
				error: err.message,
			};
		}
	}
	static async getAllAnswers(job_id, freelancer_id) {
		try {
			const res = await axios.get(`${projectV2Endpoint}jobs/get-all-answers/${job_id}/${freelancer_id}`);

			// Check if the request was successful
			if (res.data.success) {
				return {
					success: true,
					result: res.data.result
				};
			} else {
				// The server responded, but indicated a failure
				return {
					success: true,
					result: []
				};
			}
		} catch (err) {
			// There was an error with the request
			console.error(err);
			return {
				success: false,
				error: err.response.data.detail,
			};
		}
	}

	static async getAllPublicAnswers(job_id, freelancer_id) {
		try {
			const res = await axios.get(`${projectV2Endpoint}jobs/get-all-public-answers/${job_id}/${freelancer_id}`);

			// Check if the request was successful
			if (res.data.success) {
				return {
					success: true,
					result: res.data.result
				};
			} else {
				// The server responded, but indicated a failure
				return {
					success: true,
					result: []
				};
			}
		} catch (err) {
			// There was an error with the request
			console.error(err);
			return {
				success: false,
				error: err.response.data.detail,
			};
		}
	}

	static async updateVideoAnsIsPublicFlag(jobId, flag) {
		let response;
		await axios
			.patch(projectV2Endpoint + `jobs/update_video_ans_is_public_flag/${jobId}?flag=${flag}`)
			.then((res) => response = res.data);
		return response
	}
}

export default JobService;
