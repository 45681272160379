import React, { lazy } from "react";
import Authenticated from "src/components/Authenticated";
import CollapsedSidebarLayout from "src/layouts/CollapsedSidebarLayout";
import Loader from "src/components/Loader";

const Proposal = Loader(
    lazy(() => import("src/content/proposalFlow"))
)

const ProposalReview = Loader(
    lazy(() => import("src/content/proposalReview"))
)

const ProposalRoutes = [
    {
        path: "edit-proposal",
        element: <Authenticated>
            <CollapsedSidebarLayout />
        </Authenticated>,
        children: [{
            path: ":taskID",
            element: <Proposal />
        }]
    },
    {
        path: "negotiate-proposal",
        element: <Authenticated>
            <CollapsedSidebarLayout />
        </Authenticated>,
        children: [{
            path: ":proposalID/:type",
            element: <Proposal isInNegotiationMode />
        }]
    },
    {
        path: "view-proposal",
        element: <Authenticated>
            <CollapsedSidebarLayout />
        </Authenticated>,
        children: [{
            path: ":proposalId/:type",
            element: <ProposalReview />
        }]
    },
    {
        path: "view-contract",
        element: <Authenticated>
            <CollapsedSidebarLayout />
        </Authenticated>,
        children: [{
            path: ":proposalId/:type",
            element: <ProposalReview contractMode />
        }]
    },
]

export default ProposalRoutes;