import axios from "axios";
import { vendorEndpoint } from "../../../mrkt365config";

class VideoInterviewService {
    static async getAllQuestions() {
        var questions;
        await axios.get(vendorEndpoint + "get_all_questions").then((res) => {
            questions = res.data;
        });

        return questions;
    }

    static async getAllAnswers() {
        var answers;
        await axios.get(vendorEndpoint + "get_all_answers").then((res) => {
            answers = res.data;
        });

        return answers;
    }

    static async saveAnswer(formData) {
        await axios.post(vendorEndpoint + "save_video_answer", formData).then((res) => {

        });
    }

    static async skipVideoInterview() {
        await axios.patch(vendorEndpoint + `no_entity?step_name=video_interview`, "").then((res) => {

        });
    }
}

export default VideoInterviewService;
