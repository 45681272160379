/* eslint-disable no-return-await */
import { vendorEndpoint } from "src/mrkt365config";
import { GET as CORE_GET } from "./helpers";

// url path formatter
const upf = (path = "") => vendorEndpoint + path;

const GET = (path = "") => CORE_GET(upf(path));

export const fetchUserExtraInfo = () => GET("get_extra_info");

export const fetchUserSkills = () => GET("get_all_skills");

// export const fetchUserHourlyRate = () => GET("get_hourly_rate");

export const fetchUserProfileInfo = async (data) =>
    await GET(`super_search_filter_public?profile_id=${data?.profile_id}`);

export const fetchUserProfileInfoByUserId = async (user_id) => await GET(`super_search_filter?user_id=${user_id}`);
export const fetchPublicUserProfileInfoByUserId = async (user_id) => await GET(`super_search_filter_public?user_id=${user_id}`);

// export const fetchUserExperiences = () => GET("get_experiences");

export const fetchUserEducations = () => GET("get_educations");

// export const fetchPortfolio = () => GET("get_portfolios");

// export const fetchAllQuestions = () => GET("get_all_questions");
