import React, { useState } from "react";
import LoginIcon from "@mui/icons-material/Login";
import { Button, Grid } from "@mui/material";
import LoginPopUp from "../LoginPopUp";

export default function PublicViewHeader(props) {
    const [showLogInPopUP, setShowLogInPopUP] = useState(false);
    return (
        <>
            <div style={{ height: "80px" }} />
            <Grid
                display="flex"
                justifyContent="space-between"
                sx={{
                    padding: "18px",
                    backgroundColor: "white",
                    boxShadow: 1,
                    position: "fixed",
                    width: "100%",
                    zIndex: "100",
                    top: 0
                }}
            >
                <img src="/mrkt365logo_small.png" alt="Marketplace" style={{ objectFit: "contain" }} />
                <Button onClick={() => setShowLogInPopUP(true)} startIcon={<LoginIcon />} variant="outlined">
                    Login
                </Button>
                {showLogInPopUP ? (
                    <LoginPopUp showLogInPopUP={showLogInPopUP} setShowLogInPopUP={setShowLogInPopUP} />
                ) : null}
            </Grid>
        </>
    );
}
