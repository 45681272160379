import { Box, Typography, Container, Button, styled, Snackbar, CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet-async";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import MuiAlert from "@mui/material/Alert";
import React, { useState, forwardRef, useEffect } from "react";
import useAuth from "src/hooks/useAuth";
import Cookies from "js-cookie";
import InfoIcon from "@mui/icons-material/Info";
import EmailVerificationService from "./emailVerificationService";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { userWebSocketEndpoint } from "src/mrkt365config";

const Alert = forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MainContent = styled(Box)(
    () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const VerifyEmail = () => {
    const { t } = useTranslation();
    const { initialize } = useAuth();
    const [errorSnack, setErrorSnack] = useState(false);
    const [successSnack, setSuccessSnack] = useState(false);
    const [sendingTagain, setSendingTagain] = useState(false)
    const email = Cookies.get("email")
    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
        userWebSocketEndpoint + "verify_email/" + email
    );

    useEffect(() => {
        if (!lastMessage) return
        const data = JSON.parse(lastMessage.data)
        if (data.email_verified) initialize()
        else setErrorSnack(true)
    }, [lastMessage])

    const closeSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrorSnack(false);
        setSuccessSnack(false);
    };


    const sendNewToken = async () => {
        setSendingTagain(true)
        if (await EmailVerificationService.sendNewToken(email)) {
            setSuccessSnack(true);
        } else {

        }
        setSendingTagain(false)
    };

    return (
        <>
            <Helmet>
                <title>Verify Email</title>
            </Helmet>
            <MainContent>
                <Container maxWidth="md">
                    <Box textAlign="center">
                        <Typography
                            variant="h2"
                            sx={{
                                my: 2,
                            }}
                        >
                            {t(
                                "To proceed further please verify your Email by clicking on the link sent to your Email"
                            )}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="text.secondary"
                            fontWeight="normal"
                            sx={{
                                mb: 4,
                            }}
                        >
                            {t("Click below to send the link again.")}
                        </Typography>
                    </Box>
                    <div style={{ textAlign: "center", margin: "auto", }}>
                        <Button
                            variant="outlined"
                            sx={{ mr: 2 }}
                            endIcon={<SendIcon />}
                            startIcon={sendingTagain && <CircularProgress size="1rem" />}
                            onClick={sendNewToken}
                            disabled={sendingTagain}
                        >
                            {t("Send again")}
                        </Button>

                        <Button variant="contained" onClick={() => sendMessage("{}")}>
                            {t("Check verification")}
                        </Button>
                    </div>
                </Container>

                <Typography
                    variant="h5"
                    sx={{
                        paddingTop: 13,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <InfoIcon />
                    </div>

                    {t("Having trouble locating your verification email?")}
                </Typography>

                <Typography variant="h5" sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    {t("Check the spam folder and move the email to your inbox")}
                </Typography>
            </MainContent>

            <Snackbar open={errorSnack} autoHideDuration={6000} onClose={(e, r) => closeSnack(e, r)}>
                <Alert onClose={(e, r) => closeSnack(e, r)} severity="error" sx={{ width: "100%" }}>
                    Not verified yet!
                </Alert>
            </Snackbar>

            <Snackbar open={successSnack} autoHideDuration={6000} onClose={(e, r) => closeSnack(e, r)}>
                <Alert onClose={(e, r) => closeSnack(e, r)} severity="success" sx={{ width: "100%" }}>
                    New verification email has been sent
                </Alert>
            </Snackbar>
        </>
    );
};

export default VerifyEmail;
