import React from "react";

const ErrorView = () => (
    <div style={{ margin: "0 20px" }}>
        We are unable to access your camera or microphone.
        <br />
        <br />
        If you have both camera and microphone connected, kindly provide those access to this website via your browser
        settings.
    </div>
);

export default ErrorView;
