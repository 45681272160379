import React from "react";
import { Tooltip, tooltipClasses, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LogoWrapper = styled(Link)(
    ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.colors.alpha.trueWhite[100],
            color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
            fontSize: theme.typography.pxToRem(12),
            fontWeight: "bold",
            borderRadius: theme.general.borderRadiusSm,
            boxShadow: "0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)",
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.colors.alpha.trueWhite[100],
        },
    })
);

function Logo() {
    const { t } = useTranslation();

    return (
        <TooltipWrapper title={t("Mrkt365 Platform")} arrow>
            <LogoWrapper to="/">
                <img src={`/ai_brain-50x51.png`} alt={"mrkt365 logo"} />
            </LogoWrapper>
        </TooltipWrapper>
    );
}

export default Logo;
