import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '270cbeb3-6f72-46b8-9f13-428ee6c43aca', 
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
  },
});

// appInsights.loadAppInsights();

export { appInsights, reactPlugin };
