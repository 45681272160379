import React, { lazy } from "react";
import Authenticated from "src/components/Authenticated";
import CollapsedSidebarLayout from "src/layouts/CollapsedSidebarLayout";
import Loader from "src/components/Loader";
import { UserTypeLabels } from "src/constants";

const MarketPlace = Loader(lazy(() => import("../content/marketplace")));
const OpenMarketPlace = Loader(lazy(() => import("../content/open-marketplace")));

const MarketplaceRoutes = [
    {
        path: "marketplace",
        element: (
            <Authenticated onlyAllow={[UserTypeLabels.BUSINESS, UserTypeLabels.BUSINESS_MANAGER]}>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <MarketPlace />,
            },
        ],
    },

    {
        path: "marketplace",
        children: [
            {
                path: "search",
                element: <OpenMarketPlace />,
            },
        ],
    },]


export default MarketplaceRoutes;