import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const ClickableToolTip = ({ children, ...allProps }) => {
	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = () => {
		setOpen(false);
	};

	const handleTooltipOpen = () => {
		setOpen(true);
	};
	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div>
				<Tooltip
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					onOpen={handleTooltipOpen}
					open={open}
					{...allProps}
					arrow={true}
				>
					{children ? <span onClick={handleTooltipOpen}>{children}</span> : <span />}
				</Tooltip>
			</div>
		</ClickAwayListener>
	);
};

export default ClickableToolTip;
