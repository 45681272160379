import AnalyticsTwoToneIcon from "@mui/icons-material/AnalyticsTwoTone";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import GridViewIcon from '@mui/icons-material/GridView';

function freelancerMenuItems(isEmployee = true) {
    var navs = [
        {
            heading: "General",
            items: [
             
                {
                    name: "Dashboard",
                    icon: GridViewIcon,
                    link: "/dashboard",
                },
                {
                    name: "Tracker",
                    icon: AnalyticsTwoToneIcon,
                    link: "/download-tracker",
                },
            ],
        },
    ];
    if (!isEmployee)
        navs[0].items.push({
            name: "Job Board",
            icon: AssignmentIndTwoToneIcon,
            link: "/job-board",
        });
    if (!isEmployee)
        navs[0].items.push({
            name: "Payouts",
            icon: StorefrontTwoToneIcon,
            link: "/payouts",
        });
    return navs;
}

export default freelancerMenuItems;
