function trimText(text, count) {
	return text ? text.substr(0, count) + (text.length > count ? '...' : '') : '';
}

const getCurrentRelativeUrl = () => window.location.pathname + window.location.search;

function getDateObject(date) {
	return new Date(date);
}

function formatDateTo4Y2M2D(date) {
	date = getDateObject(date);
	var month = '' + (date.getMonth() + 1);
	var day = '' + date.getDate();
	var year = date.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
}

function formatDateTo2DMonth4Yformat(date) {
	date = getDateObject(date);
	return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
}

function getTimeFromDate(date) {
	return date.getHours() + ':' + date.getMinutes();
}

const removeNullValsFromArray = (data = []) => {
	if (data) {
		return data.filter((val) => !!val);
	} else {
		return [];
	}
};

const titilize = (str) => {
	if (str) {
		return str[0].toUpperCase() + str.slice(1);
	} else {
		return '';
	}
};

export {
	trimText,
	formatDateTo4Y2M2D,
	getCurrentRelativeUrl,
	removeNullValsFromArray,
	titilize,
	formatDateTo2DMonth4Yformat,
};
