import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Authenticated from "src/components/Authenticated";
import Loader from "src/components/Loader";

const Login = Loader(lazy(() => import("src/content/pages/Auth/Login/Cover")));
const Logout = Loader(lazy(() => import("../content/logout")));
const RegisterPage = Loader(lazy(() => import("../content/register")));
const Oauth2RegisterFailedView = Loader(lazy(() => import("../content/register/oauth2RegistrationFailed")));
const VerifyEmailPage = Loader(lazy(() => import("../content/verifyEmail/verifyEmail")));
const EmailVerificationPage = Loader(lazy(() => import("../content/verifyEmail/emailVerification")));
const ForgotPassword = Loader(lazy(() => import("../content/forgot-password/forgotPassword")));
const ResetPassword = Loader(lazy(() => import("../content/forgot-password/resetPassword")));
const Oauth2Login = Loader(lazy(() => import("../content/login/oauth2Login")));
const FreelancerProductList = Loader(lazy(() => import("../content/onboarding-flow/freelancer/index")));
const BusinessProductList = Loader(lazy(() => import("../content/onboarding-flow/business/index")));
const CompleteYourProfile = Loader(lazy(() => import("../content/dashboard/freelancer/completeYourProfile")));
const FindJobs = Loader(lazy(() => import("../content/dashboard/freelancer/freelanerHome")));


const AuthRoutes = [
    // Login
    {
        path: "login",
        element: <Authenticated>
            <Navigate to="/" />
        </Authenticated>
    },
    // Logout
    {
        path: "logout",
        element: <Logout />,
    },
    // Registeration
    {
        path: "register",
        element: <RegisterPage />,
    },
    {
        path: "register/oauth2-registration-failed",
        element: <Oauth2RegisterFailedView />,
    },
    {
        path: ":service/callback/register",
        element: <RegisterPage />,
    },
    // verify-email
    {
        path: "verify-email",
        element: (
            <Authenticated>
                <VerifyEmailPage />
            </Authenticated>
        ),
    },
    // email-verification
    {
        path: "email-verification",
        element: <EmailVerificationPage />,
    },
    {
        path: "forgot-password",
        element: <ForgotPassword />,
    },
    {
        path: "reset-password",
        element: <ResetPassword />,
    },
    {
        path: ":service/callback",
        element: <Oauth2Login />
    },
    {
        path: "browse-jobs",
        element: <FreelancerProductList />
    },
    {
        path: "browse-talent",
        element: <BusinessProductList />
    },

    {
        path: "complete-your-profile",
        element: <CompleteYourProfile />
    },
    {
        path: "find-jobs",
        element: <FindJobs />
    },

]

export default AuthRoutes;