import React from 'react';
import BaseLayout from 'src/layouts/BaseLayout';
import baseRoutes from './base';
// import SEORoutes from "./seo"
import AuthRoutes from './auth';
import ProfileRoutes from './profile';
import ProjectTaskRoutes from './project-and-task';
import FinanceRoutes from './finances';
import JobBoardRoutes from './job-board';
import MessengerRoutes from './messenger';
// import vccRoutes from "./virtual-card";
import ManageTeamRoutes from './manage-team';
import MarketplaceRoutes from './marketplace';
import ProposalRoutes from './proposal';

const router = [
	{
		path: '',
		element: <BaseLayout />,
		children: baseRoutes,
	},
	...AuthRoutes,
	...ProfileRoutes,
	...ProjectTaskRoutes,
	...MarketplaceRoutes,
	...FinanceRoutes,
	...JobBoardRoutes,
	...ManageTeamRoutes,
	...ProposalRoutes,
	...MessengerRoutes,
	// ...SEORoutes,
];

export default router;
