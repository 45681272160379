import React from "react";
import { Box, Typography, Container, Button, Grid, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import JustLogoHeader from "src/components/JustLogoHeader";
import { useTranslation } from "react-i18next";

const GridWrapper = styled(Grid)(
    ({ theme }) => `
    background: ${theme.colors.gradients.black1};
`
);

const MainContent = styled(Box)(
    () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[100]};
`
);

const TypographySecondary = styled(Typography)(
    ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[70]};
`
);

function SuspendedView() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Account Suspended</title>
            </Helmet>
            <JustLogoHeader />
            <MainContent>
                <Grid
                    container
                    sx={{
                        height: "100%",
                    }}
                    alignItems="stretch"
                    spacing={0}
                    justifyContent="center"
                >
                    <Grid alignItems="center" display="flex" justifyContent="center" item>
                        <Container>
                            <Box textAlign="center">
                               
                                <Typography
                                    variant="h2"
                                    sx={{
                                        my: 2,
                                    }}
                                >
                                    {t("Your account has been suspended")}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    color="text.secondary"
                                    fontWeight="normal"
                                    sx={{
                                        mb: 4,
                                    }}
                                >
                                    {t("Please contact our support team for more information.")}
                                </Typography>
                                <Button href="/contact-support" variant="contained" target="_blank">
                                    {t("Contact support")}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        ml: 1,
                                    }}
                                    href="/logout"
                                >
                                    {t("Log Out")}
                                </Button>
                            </Box>
                        </Container>
                    </Grid>
                </Grid>
            </MainContent>
        </>
    );
}

export default SuspendedView;
