/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Box, Card, Link, Typography, Container, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import useAuth from "src/hooks/useAuth";
import Auth0Login from "../LoginAuth0";
import FirebaseAuthLogin from "../LoginFirebaseAuth";
import JWTLogin from "../LoginJWT";
import AmplifyLogin from "../LoginAmplify";

const Content = styled(Box)(
    () => `
    display: flex;
    flex: 1;
    width: 100%;
    height:100%;
    @media only screen and (max-width: 767px) {height:auto; margin-top:30px; padding-bottom:30px; }
`
);

const MainContent = styled(Box)(
    () => `
  padding: 0 0 0 440px;
  width: 100%;
  display: flex;
  align-items: center;
`
);

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = styled(Box)(
    ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);

const CardImg = styled(Card)(
    ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(["border"])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const TypographyH1 = styled(Typography)(
    ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);



function LoginCover() {
    const { method } = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [thirdPartySignInWindow, setThirdPartySignInWindow] = useState(null);

    useEffect(
        () => () => {
            if (thirdPartySignInWindow && !thirdPartySignInWindow.closed) {
                thirdPartySignInWindow.close();
            }
        },
        []
    );

    const closeGoogleSignInWindow = () => {
        if (thirdPartySignInWindow && !thirdPartySignInWindow.closed) {
            thirdPartySignInWindow.close();
        }
    };

    const handleGoToRegister = () => {
        closeGoogleSignInWindow();
        navigate(
            window.location.pathname === "/login"
                ? "/register"
                : `/register?next=${window.location.pathname + encodeURIComponent(window.location.search)}`
        );
    };

    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Content className="wrapData"
               
            >
                <MainContent
                    style={{
                        padding: 0, 
                    }}
                >
                    <Container
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            padding: 0,
                            width:"700px",
                            "@media screen and (max-width: 767px)": {width:"90%",} 
                        }}
                        
                    >
                        <Card
                            sx={{
                                p: 4,
                                my: 4,
                                width: "100%",
                                margin: isMobile ? 0 : 3,
                                minHeight: isMobile ? "100vh" : "auto",
                                pt:1,
                                pb:1,
                            }}
                        >
                            <Box textAlign="center">
                                <Typography sx={{width:"300px", margin:"auto", "@media screen and (max-width: 480px)": {width:"100%",} }}><img src="/mrkt365logo.png" alt="Mrkt365 Logo" width="100%"  /></Typography>
                                
                                <Typography
                                    variant="h3"
                                    sx={{
                                        my: 1,
                                    }}
                                >
                                    {t("Sign in")}
                                </Typography>
                               
                            </Box>
                            {method === "Auth0" && <Auth0Login />}
                            {method === "FirebaseAuth" && <FirebaseAuthLogin />}
                            {method === "JWT" && (
                                <JWTLogin
                                    setThirdPartySignInWindow={setThirdPartySignInWindow}
                                    closeAllOpenedChildWindows={closeGoogleSignInWindow}
                                />
                            )}
                            {method === "Amplify" && <AmplifyLogin />}
                            <Box my={4} mt={1} mb={0} sx={{"@media screen and (max-width: 767px)":{textAlign:"center"} }}>
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                    color="text.primary"
                                    fontWeight="bold"
                                >
                                    {t("Don’t have an account, yet?")}
                                </Typography>

                                <Link sx={{ cursor: "pointer", "@media screen and (max-width: 767px)": {display:"block", marginTop:"5px",} }}  onClick={handleGoToRegister}>
                                    &nbsp;<b>Sign up</b>
                                </Link>
                            </Box>
                        </Card>
                    </Container>
                </MainContent>
            </Content>
        </>
    );
}

export default LoginCover;
