import React from 'react';
import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ClickableToolTip from '.';

const ClickableInfoToolTip = ({ iconColor = '#aaa', ...props }) => {
	return (
		<ClickableToolTip {...props}>
			<IconButton>
				<InfoOutlinedIcon sx={{ color: iconColor, fontSize: '21px' }} />
			</IconButton>
		</ClickableToolTip>
	);
};

export default ClickableInfoToolTip;
