import React, { createContext, useReducer } from "react";

export const OnboardingSteps = {
    BASICINFO: "basicInfo",
    SKILLS: "skills",
    PRICE: "price",
    EXPERIENCE: "experience",
    EDUCATION: "education",
    PORTFOLIO: "portfolio",
    PAYONEER: "payoneer",
    VIDEO_INTERVIEW: "videoInterview",
};

const initValue = {
    currStep: 0,
    completedUpto: 0,
    onBoardingData: {
        [OnboardingSteps.BASICINFO]: {},
        [OnboardingSteps.SKILLS]: [],
        [OnboardingSteps.PRICE]: 0.0,
        [OnboardingSteps.EXPERIENCE]: { noExperience: false, expList: [] },
        [OnboardingSteps.PORTFOLIO]: { noProjects: false, projList: [] },
        [OnboardingSteps.EDUCATION]: { noEducation: false, eduList: [] },
        [OnboardingSteps.PAYONEER]: { status: 1, details: {} },
        [OnboardingSteps.VIDEO_INTERVIEW]: {},
    },
};

const handlers = {
    ADDSTEP: (state, action) => {
        return {
            ...state,
            currStep: state.currStep < state.completedUpto ? state.currStep + 1 : state.currStep,
        };
    },
    SUBSTEP: (state, action) => {
        return {
            ...state,
            currStep: state.currStep - 1,
        };
    },
    GOTOSTEP: (state, { goToStep }) => {
        if (state.completedUpto >= goToStep)
            return {
                ...state,
                currStep: goToStep,
            };
        return { ...state };
    },
    UPDATEDATA: (state, { label, data }) => {
        const tempData = { ...state.onBoardingData };
        tempData[label] = data;
        return {
            ...state,
            onBoardingData: tempData,
        };
    },
    SETCOMPLETEDUPTO: (state, { completedUpto }) => {
        if (state.completedUpto < completedUpto)
            return {
                ...state,
                completedUpto: completedUpto,
            };
        return { ...state };
    },
    SKIPSTEP: (state) => {
        return {
            ...state,
            currStep: state.currStep + 1,
            completedUpto: state.completedUpto > state.currStep ? state.completedUpto : state.completedUpto + 1,
        };
    },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

export const OnBoardContext = createContext({
    ...initValue,
    addStep: () => {},
    subStep: () => {},
    updateData: () => {},
    setCompletedUpto: () => {},
});

export const OnBoardProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initValue);
    const addStep = () => {
        dispatch({ type: "ADDSTEP" });
    };
    const subStep = () => {
        dispatch({ type: "SUBSTEP" });
    };
    const goToStep = (tempGoToStep) => {
        dispatch({ type: "GOTOSTEP", goToStep: tempGoToStep });
    };
    const updateData = (payload) => {
        dispatch({ type: "UPDATEDATA", ...payload });
    };
    const setCompletedUpto = (completedUpto) => {
        dispatch({ type: "SETCOMPLETEDUPTO", completedUpto });
    };
    const skipStep = () => {
        dispatch({ type: "SKIPSTEP" });
    };
    return (
        <OnBoardContext.Provider
            value={{ ...state, addStep, subStep, skipStep, goToStep, updateData, setCompletedUpto }}
        >
            {children}
        </OnBoardContext.Provider>
    );
};
