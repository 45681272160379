import React, { useContext, useEffect, useState } from "react";
import { Box, IconButton, Tooltip, styled, Typography, Badge } from "@mui/material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { Chat } from "@mui/icons-material";
import { SidebarContext } from "src/contexts/SidebarContext";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Logo from "src/components/Logo";
import OnlineStatusSyncerService from "src/content/messenger/services/OnlineStatusSyncerService";
import useWebSocket from "react-use-websocket";
import AuthContext from "src/contexts/JWTAuthContext";
import VideoCallManagement from "src/content/video-answer/index";
import { unreadChatsWebsocketUrl } from "../../../mrkt365config";
import HeaderMenu from "./Menu";
import HeaderUserbox from "./Userbox";

const HeaderWrapper = styled(Box)(
    ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        top: 0;
        z-index: 100;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
`
);

function Header() {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
    const [numberOfChatsWithUnreadMessages, setNumberOfChatsWithUnreadMessages] = useState(0);

    const { user } = useContext(AuthContext);

    var target_websocket_url = `${unreadChatsWebsocketUrl}${user.id}`;

    const { sendMessage, lastMessage } = useWebSocket(target_websocket_url, {
        share: true,
        shouldReconnect: () => true,
    });

    useEffect(() => {
        if (lastMessage) {
 
            var received_payoad_data = JSON.parse(lastMessage.data);
            if (received_payoad_data) {
                setNumberOfChatsWithUnreadMessages(received_payoad_data.unread_chats_count);
            }
            // reportMessageReceived(received_payoad_data);
        }
    }, [lastMessage]);

    useEffect(() => {
        const startSyncingUsersUnreadChatCounterData = () => {
            // var is_currently_on_messenger_page = window.location.pathname.includes("messenger");
            var timer = null;
            // if (is_currently_on_messenger_page !== true) {
            timer = setInterval(() => {
      
                sendMessage(JSON.stringify({}));
                clearInterval(timer);
            }, 100);
            // }

            return function cleanup() {
                clearInterval(timer);
            };
        };
        OnlineStatusSyncerService.refreshUsersOnlineStatus();
        const refreshUserIntervalId = setInterval(OnlineStatusSyncerService.refreshUsersOnlineStatus, 5000 * 60)
        startSyncingUsersUnreadChatCounterData();

        return () => {
            clearInterval(refreshUserIntervalId)
        }
    }, []);

    const openMessenger = () => {
        window.open("/messenger");
    };

    return (
        <HeaderWrapper display="flex" alignItems="center">
            <Box display="flex" alignItems="center" sx={{ width: "100%", justifyContent: "flex-start" }}>
                <Box component="span">
                    <Logo />
                </Box>
                <Box
                    component="span"
                    sx={{
                        display: {
                            xs: "none",
                            width: "100%",
                            position: "relative",
                        },
                        "@media screen and (min-width: 1200px)": {
                            display: "inline-block",
                        },
                    }}
                >
                    <HeaderMenu />
                </Box>
            </Box>
            <Box display="flex" alignItems="center">

                {window.location.pathname !== "/messenger" ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginRight: "0.3rem",
                        }}
                        id="chatInHeader" // this is for joyride(demo) purpose
                    >
                        <IconButton
                            onClick={openMessenger}
                            sx={{
                                height: "100%",
                                padding: "10px",
                            }}
                        >
                            <Badge
                                badgeContent={
                                    numberOfChatsWithUnreadMessages > 0 ? numberOfChatsWithUnreadMessages : 0
                                }
                                color="success"
                            >
                                <Chat sx={{ marginRight: "0.5rem" }} color="success" />
                                <Typography variant="h4" color="#57CA22">
                                    Chat
                                </Typography>
                            </Badge>
                        </IconButton>
                    </Box>
                ) : null}
                <HeaderUserbox />
                <Box
                    component="span"
                    sx={{
                        display: "inline-block",
                        "@media screen and (min-width: 1200px)": {
                            display: "none",
                        },
                    }}
                    id="burgerIconInHeader" // this is for joyride(demo) purpose
                >
                    <Tooltip arrow title="Toggle Menu">
                        <IconButton color="primary" onClick={() => toggleSidebar()}>
                            {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
                <VideoCallManagement />
            </Box>
            {/* to add overlay to header while WT demo images are being shown */}
            <div className="header__overlay" id="headerOverlay"></div>
        </HeaderWrapper>
    );
}

export default Header;
