import { Alert, Box, Snackbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import SuspenseLoader from 'src/components/SuspenseLoader';
import VideoInterview from 'src/content/onboarding/components/videoInterview';
import useAuth from 'src/hooks/useAuth';
import JobService from '../../services/jobService';
import { useParams } from 'react-router-dom';

const RecordVideoInterview = ({ finishRecording }) => {
	const { user, basic_info, initialize, isAuthenticated } = useAuth();
	const { jobId } = useParams();
	const [successSnack, setSuccessSnack] = useState(false);
	const [deleteSnack, setDeleteSnack] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [jobData, setJobData] = useState(null);

	const openSnack = (type) => {
		if (type === 'success') setSuccessSnack(true);
		else if (type === 'delete') setDeleteSnack(true);
	};
	const closeSnack = (event, reason, type) => {
		if (reason === 'clickaway') {
			return;
		}
		if (type === 'success') setSuccessSnack(false);
		else if (type === 'delete') setDeleteSnack(false);
	};

	useEffect(async () => {
		if (isAuthenticated) {
			console.log('Authenticated user');
			const fetchJobData = async () => {
				try {
					const data = await JobService.getJob(jobId);
					console.log(data);
					setJobData(data?.job?.Job);
				} catch (error) {
					console.error('Failed to fetch job data:', error);
				} finally {
					setIsLoading(false);
				}
			};
			await fetchJobData();
		} else {
			console.log('not authencticated');
		}
	}, [jobId]);

	return (
		<>
			<Helmet>
				<title>#{jobId} Job</title>
			</Helmet>
			{isLoading ? (
				<SuspenseLoader />
			) : (
				<>
					<VideoInterview
						label="Job Video Interview"
						showNavButtons={false}
						openSnack={openSnack}
						jobId={jobId}
						job={jobData}
					/>
					<Snackbar open={successSnack} autoHideDuration={6000} onClose={(e, r) => closeSnack(e, r, 'success')}>
						<Alert onClose={(e, r) => closeSnack(e, r, 'success')} severity="success" sx={{ width: '100%' }}>
							Successully saved!
						</Alert>
					</Snackbar>
					<Snackbar open={deleteSnack} autoHideDuration={6000} onClose={(e, r) => closeSnack(e, r, 'delete')}>
						<Alert onClose={(e, r) => closeSnack(e, r, 'delete')} severity="info" sx={{ width: '100%' }}>
							Successully deleted!
						</Alert>
					</Snackbar>
				</>
			)}
		</>
	);
};

export default RecordVideoInterview;
