import React, { useState, createContext } from "react";

export const SidebarContext = createContext({});

export const SidebarProvider = ({ children }) => {
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [gscSidebarToggle, setGSCSidebarToggle] = useState(false);
    const [seoSidebarToggle, setSEOSidebarToggle] = useState(false);

    const toggleSidebar = (sidebar = "") => {
        if (sidebar === "") setSidebarToggle(!sidebarToggle);
        else if (sidebar === "gsc") setGSCSidebarToggle(!gscSidebarToggle);
        else if (sidebar === "seo") setSEOSidebarToggle(!seoSidebarToggle);
    };
    const closeSidebar = () => {
        if (sidebarToggle) setSidebarToggle(false);
        if (gscSidebarToggle) setGSCSidebarToggle(false);
        if (seoSidebarToggle) setSEOSidebarToggle(false);
    };

    return (
        <SidebarContext.Provider
            value={{ sidebarToggle, toggleSidebar, closeSidebar, gscSidebarToggle, seoSidebarToggle }}
        >
            {children}
        </SidebarContext.Provider>
    );
};
