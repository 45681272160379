/* eslint-disable func-names */
import React, { useState } from "react";
import { ThemeProvider } from "@mui/material";
import { StylesProvider } from "@mui/styles";
import { themeCreator } from "./base";


export const ThemeContext = React.createContext();

const ThemeProviderWrapper = function (props) {
    const curThemeName = localStorage.getItem("appTheme") || "PureLightTheme";
    const [themeName, _setThemeName] = useState(curThemeName);
    const theme = themeCreator(themeName);
    const setThemeName = (tempThemeName) => {
        localStorage.setItem("appTheme", tempThemeName);
        _setThemeName(tempThemeName);
    };

    return (
        <StylesProvider injectFirst>
            {/* <CacheProvider value={cacheRtl}> */}
            <ThemeContext.Provider value={setThemeName}>
                <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
            </ThemeContext.Provider>
            {/* </CacheProvider> */}
        </StylesProvider>
    );
};

export default ThemeProviderWrapper;
