import React, { useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";

const AppContext = React.createContext({});


export const AppContextProvider = ({ children }) => {
    const { isAuthenticated, isInitialized, user, basic_info } = useAuth();
    const [extraData, setExtraData] = useState({})

    useEffect(() => console.log(extraData), [extraData])


    return (
        <AppContext.Provider
            value={{
                isAuthenticated,
                isInitialized,
                userDetails: user,
                // userDetails: formattedUserInfo,
                vendorDetails: basic_info,
                extraData,
                setExtraData
                // countryList: countriesList,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
