import { useState, useEffect } from 'react';
import {
    Avatar,
    Card,
    Container,
    FormControl,
    List,
    ListItem,
    ListItemText,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,

} from "@mui/material";
import JustLogoHeader from 'src/components/JustLogoHeader';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import "./style.css";
import { useNavigate, Link } from 'react-router-dom';
import ClickableInfoToolTip from 'src/components/ClickableToolTip/clickableInfoToolTip';

const OnboardingFlow = () => {
    const [value, setValue] = useState('');
    const navigate = useNavigate()
    const handleChange = (event) => {
        setValue((event.target).value);
    };
    useEffect(() => {
        if (value === "freelancer") navigate("/browse-jobs")
        if (value === "business") navigate("/browse-talent")
    }, [value])


    return (
        <>
            <JustLogoHeader />
            <Container sx={{ my: 4, mt:2, }}>
                <FormControl sx={{ width: "100%" }}>
                    <Typography sx={{ fontSize: "36px", fontWeight: "700", textAlign: "center", color: "#223354", }}>Join as a Business or Freelancer</Typography>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                    >
                        <Grid container spacing={2} sx={{}}>
                            <Grid item container sm={12} columnSpacing={2} sx={{paddingTop:"0", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "40px", }}>
                                <Grid sx={{ height: "100%", cursor: "pointer", "@media screen and (max-width: 991px)": { height: "auto", } }} item xs={12} md={4} onClick={() => setValue("freelancer")}>
                                    <Card sx={{ height: "96%", mt: 3, px: 2, py: 4, pb: 0, pt:2, mx: "auto", position: "relative",  overflow:"visible", "@media screen and (max-width: 991px)": { height: "auto", } }}>
                                        <FormControlLabel sx={{ position: "absolute", right: "-15px", top: "1px", }} value="freelancer" control={<Radio />} label="" />
                                        <Avatar sx={{ width: "120px", height: "auto", margin: "auto", borderRadius:"inherit", }} alt="Freelancer" src="/static/images/overview/businessman-show-growth2.png" />
                                        <Typography sx={{ fontSize: "36px", display:"flex", justifyContent:"center", alignItems:"center", color: "#223354", pt: 1, pb: 0 }}>Freelancer  <ClickableInfoToolTip className="headingFreTooltip iconSize"   placement="bottom" title="Become a part of the MRKT365 community and unlock exciting opportunities as a freelancer. By joining as a freelancer, you gain access to a vast marketplace where you can showcase your skills, attract clients, and grow your professional network. Sign up today and start leveraging the power of MRKT365 to take your freelance career to new heights." /> </Typography> 
                                        <List className='listSec6'>
                                            <ListItem>
                                                <ListItemText>
                                                    <Typography className='li-list' sx={{ color: "#223354", fontSize: "16px" }}>
                                                        Get Daily job recommendation based on your profile
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText>
                                                    <Typography className='li-list' sx={{ color: "#223354", fontSize: "16px" }}>
                                                        Highlight you profile to get direct jobs from recruiters
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText>
                                                    <Typography className='li-list' sx={{ color: "#223354", fontSize: "16px" }}>
                                                        Sell your service in both Hourly & fixed on lower commission rates
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </Card>
                                </Grid>
                                <Grid sx={{ height: "100%", cursor: "pointer", "@media screen and (max-width: 991px)": { height: "auto", } }} item xs={12} md={4} onClick={() => setValue("business")}>
                                    <Card sx={{ height: "96%", mt: 3, px: 2, py: 4, pb: 0, pt:2, mx: "auto", position: "relative",  overflow:"visible", "@media screen and (max-width: 991px)": { height: "auto", } }}>
                                        <FormControlLabel sx={{ position: "absolute", right: "-15px", top: "1px", }} value="business" control={<Radio />} label="" />
                                        <Avatar sx={{ width: "120px", height: "auto", margin: "auto", borderRadius:"inherit", }} alt="Business" src="/static/images/overview/businessman-show-growth3.png" />
                                        <Typography sx={{ fontSize: "36px", display:"flex", justifyContent:"center", alignItems:"center", color: "#223354", pt: 1, pb: 0 }}>Business <ClickableInfoToolTip className="headingFreTooltip iconSize"    placement="bottom" title="Become a part of MRKT365's vibrant community as a business and unlock a world of freelance talent. By joining as a business, you gain access to a diverse pool of skilled professionals ready to take on your projects. Sign up today and leverage the power of MRKT365 to find the right freelancers, collaborate efficiently, and achieve your business goals." /></Typography>
                                        <List className='listSec6'>
                                            <ListItem>
                                                <ListItemText>
                                                    <Typography className='li-list' sx={{ color: "#223354", fontSize: "16px" }}>
                                                        Find diverse talent as per category/ Industry in lower commission rates
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText>
                                                    <Typography className='li-list' sx={{ color: "#223354", fontSize: "16px" }}>
                                                        Post job requirement & get profile recommendation
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText>
                                                    <Typography className='li-list' sx={{ color: "#223354", fontSize: "16px" }}>
                                                        Track work progress & get payment security
                                                    </Typography>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>

                    </RadioGroup>
                    <Typography sx={{ fontSize: "18px", fontWeight: "400", textAlign: "center", color: "#223354", mb: 2, }}>
                        Already have an account?  <Link to="/login"><b>Sign In</b></Link></Typography>
                </FormControl>

            </Container>
        </>
    )
}

export default OnboardingFlow;
