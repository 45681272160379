import React, { lazy } from "react";
import Authenticated from "src/components/Authenticated";
import CollapsedSidebarLayout from "src/layouts/CollapsedSidebarLayout";
import Loader from "src/components/Loader";
import { UserTypeLabels } from "src/constants";

const Payout = Loader(lazy(() => import("../content/payouts")));
const StripeSuccess = Loader(lazy(() => import("../content/billing/stripe/success")));
const StripeFail = Loader(lazy(() => import("../content/billing/stripe/failure")));
const Billings = Loader(lazy(() => import("../content/billing")));
const Invoice = Loader(lazy(() => import("../content/invoice")));
const ProformaInvoice = Loader(lazy(() => import("../content/invoice/proforma-invoice")));
const ReceiptSd = Loader(lazy(() => import("../content/invoice/security-deposit-receipt")));

const FinanceRoutes = [
    {
        path: "invoice",
        children: [
            {
                path: ":invoiceId/:type",
                element: <Invoice />,
            },
        ],
    },
    {
        path: "proforma-invoice",
        children: [
            {
                path: ":invoiceId/:type",
                element: <ProformaInvoice />,
            },
        ],
    },

    {
        path: "security-deposit-receipt",
        children: [
            {
                path: "",
                element: <ReceiptSd />,
            },
        ],
    },

    // stripe
    {
        path: "stripe-success",
        element: (
            <Authenticated>
                <StripeSuccess />
            </Authenticated>
        ),
    },
    {
        path: "stripe-fail",
        element: (
            <Authenticated>
                <StripeFail />
            </Authenticated>
        ),
    },

    // payouts
    {
        path: "payouts",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <Payout />,
            },
        ],
    },

    // Billings
    {
        path: "billing",
        element: (
            <Authenticated onlyAllow={[UserTypeLabels.BUSINESS]}>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <Billings />,
            },
        ],
    },]

export default FinanceRoutes;