import React from "react";
import { Card, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import "./styles.scss";

export default function IncomingAndOutgoingCallCard({ isIncomingCall, callerDetails, acceptCall, rejectCall }) {
    return (
        <Card className="call-card-container">
            <div className="call-card">
                <div className="call-card-header">
                    <div className="call-card-header-avatar-container">
                        <img
                            src={callerDetails ? callerDetails.avatar : ""}
                            alt="avatar"
                            className="call-card-header-avatar-image"
                        />
                    </div>
                    <div className="call-card-header-info-container">
                        <div className="call-card-header-info-name-text">
                            {callerDetails ? callerDetails.name : ""}
                        </div>
                        <div className="call-card-header-info-call-type-text">
                            {isIncomingCall ? "Incoming call..." : "Outgoing call..."}
                        </div>
                    </div>
                </div>
                <div className="call-card-actions-container">
                    <Button className="call-card-action-button" onClick={rejectCall} variant="contained" color="error">
                        <ClearIcon className="call-card-action-button-icon" />
                    </Button>
                    {isIncomingCall ? (
                        <Button
                            className="call-card-action-button"
                            onClick={acceptCall}
                            variant="contained"
                            color="success"
                        >
                            <CheckIcon className="call-card-action-button-icon" />
                        </Button>
                    ) : null}
                </div>
            </div>
        </Card>
    );
}
