import React from "react";
import "./styles.css";

export default function LinkedinAuthButton(props) {
    return (
        <div className="linkedin-auth-button-container">
            <div className="linkedin-auth-button" onClick={props.customOnClick}>
                <img
                    src="/static/images/logo/linkedin-logo.svg"
                    alt="linkedin icon"
                    className="social-integrations-logo"
                />
                <div className="linkedin-auth-button-text">{props.buttonText}</div>
            </div>
        </div>
    );
}   
