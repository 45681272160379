import React from "react";
import "./styles.css";

function GoogleAuthButton(props) {
    return (
        <div className="google-auth-button-container">
            <div className="google-auth-button" onClick={props.customOnClick}>
                <img src="/static/images/logo/google.svg" alt="google icon" />
                <div className="google-auth-button-text">{props.buttonText}</div>
            </div>
        </div>
    );
}

export default GoogleAuthButton;
