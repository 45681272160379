import AnalyticsTwoToneIcon from "@mui/icons-material/AnalyticsTwoTone";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import IntegrationInstructionsTwoToneIcon from "@mui/icons-material/IntegrationInstructionsTwoTone";
import GridViewIcon from '@mui/icons-material/GridView';
function businessMenuItems(isBusiness = true) {
    var navs = [
        {
            heading: "General",
            items: [

                {
                    name: "Dashboard",
                    icon: GridViewIcon,
                    link: "/dashboard",
                },
                {
                    name: "Projects",
                    icon: AccountTreeTwoToneIcon,
                    link: "/my-projects",
                },
                {
                    name: "Marketplace",
                    icon: StorefrontTwoToneIcon,
                    link: "/marketplace",
                    id: "marketplaceInSidebar", // this is for joyride(demo) purpose
                },
                {
                    name: "Teams",
                    icon: AccountTreeTwoToneIcon,
                    items: [
                      
                        {
                            name: "Favourite Profiles",
                            link: "/marketplace?fav-profiles=true",
                        },
                        {
                            name: "Tracker",
                            link: "/download-tracker",
                        },
                    ],
                },
            ],
        },
    ];
    if (isBusiness) {
      
        navs[0].items.push({
            name: "Business Listing",
            icon: AssignmentIndTwoToneIcon,
            link: "/business-listing",
        });
        navs[0].items.push({
            name: "Finances",
            icon: ReceiptTwoToneIcon,
            items: [
                {
                    name: "Billing",
                    link: "/billing",
                },
                {
                    name: "Payouts",
                    link: "/payouts",
                },
            ],
        });
      
        navs[0].items.push({
            name: "Job Board",
            icon: AssignmentIndTwoToneIcon,
            link: "/job-board",
        });
    } else
        navs[0].items.push({
            name: "Tracker",
            icon: AnalyticsTwoToneIcon,
            link: "/download-tracker",
        });
    return navs;
}

export default businessMenuItems;
