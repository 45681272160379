import { Box, useTheme } from "@mui/material";
import { Outlet, useSearchParams } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import React, { useState } from "react";
import PublicViewHeader from "src/components/PublicViewHeader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Floater from "./Floater";
import "./style.css";

const CollapsedSidebarLayout = ({ hideOutlet = false, addedInSideComponent = false }) => {
    const theme = useTheme();
    const { user, isAuthenticated } = useAuth();
    const [showDemoPopUp, setShowDemoPopUp] = useState(false);
    const [hideDemoBtn, setHideDemoBtn] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleDemoEnd = () => {
        setHideDemoBtn(false);
        setShowDemoPopUp(false);
        searchParams.delete("demo_mode");
        setSearchParams(searchParams);
    };

    return (
        <>
            {isAuthenticated ? (
                <>
                    <Box
                        sx={{
                            flex: 1,
                            height: addedInSideComponent ? `${theme.header.height}` : "100%",
                            ".Mui-FixedWrapper": {
                                ".MuiDrawer-root.MuiDrawer-docked": {
                                    ".MuiPaper-root": {
                                        left: 0,
                                    },
                                },
                            },
                        }}
                    >
                        <Header />
                        <Sidebar />
                        <Box
                            sx={{
                                position: "relative",
                                zIndex: 5,
                                display: "block",
                                flex: 1,
                                pt: !hideOutlet && `${theme.header.height}`,
                            }}
                        >
                            <Box display={hideOutlet ? "none" : "block"}>
                                {!hideOutlet ? (
                                    <Outlet context={{ handleDemoStart: () => setHideDemoBtn(true), handleDemoEnd }} />
                                ) : null}
                            </Box>
               
                        </Box>
                    </Box>
                  
                </>
            ) : (
                <>
                    <PublicViewHeader />
                    <Outlet />
                </>
            )}
        </>
    );
};

export default CollapsedSidebarLayout;
