const copyToClipboard = (textToBeCopied) => {
    const el = document.createElement("textarea");
    el.value = textToBeCopied;
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(el.value);
    document.body.removeChild(el);
}

export { copyToClipboard }