import { getCookie, setCookie } from "src/utils/cookies_manager/cookies_manager_utils";
import axios from "axios";
import { userEndpoint } from "src/mrkt365config";
import { appInsights } from "src/appInsights";

class OnlineStatusSyncerService {
    static async refreshUsersOnlineStatus() {
        var activity_expiration_time = 300; // in seconds
        var activity_is_updated = getCookie("activity_is_updated");

        // alert("executing refresh users online status");

        if (!activity_is_updated) {
            var target_api_endpoint = userEndpoint + "update_last_activity";
            await axios
                .patch(target_api_endpoint, {})
                .then((res) => {

                })
                .catch((error) => {
                    appInsights.trackException({
                        error,
                        properties: {
                            method: "refreshUsersOnlineStatus",
                        },
                    });

                });
            setCookie("activity_is_updated", true, activity_expiration_time);
        }
    }

    static async getTargetUserOnlineStatus(user_id, also_send_last_activity = false) {
        var online_status = false;
        var last_activity = null;
        var target_api_endpoint = userEndpoint + "get_online_status/" + user_id;

        var is_in_test_mode = false;
        if (is_in_test_mode !== true) {
            await axios
                .get(target_api_endpoint)
                .then((res) => {
                    if (res.data.is_online === true) {
                        online_status = true;
                    } else {
                        online_status = false;
                    }
                    last_activity = res.data.last_activity;
                })
                .catch((error) => {

                });
        }
        if (also_send_last_activity === true) {
            return {
                online_status: online_status,
                last_activity: last_activity,
            };
        } else {
            return online_status;
        }
    }
}

export default OnlineStatusSyncerService;
