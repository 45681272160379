import React, { lazy } from 'react';
import Authenticated from 'src/components/Authenticated';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import Loader from 'src/components/Loader';
import BaseLayout from 'src/layouts/BaseLayout';
import { UserTypeLabels } from 'src/constants';
import RecordVideoInterview from 'src/content/jobboard/components/RecordVideoInterview';
import ViewVideoInterview from 'src/content/jobboard/components/ViewVideoInterview';

const JobBoard = Loader(lazy(() => import('../content/jobboard/index')));
const OpenJobBoard = Loader(lazy(() => import('../content/open-job-board/index')));
const Applicants = Loader(lazy(() => import('../content/jobboard/components/Applicants/index')));
const ViewJob = Loader(lazy(() => import('../content/jobboard/components/ViewJob/index')));
const ViewOpenJob = Loader(lazy(() => import('../content/jobboard/components/ViewJob/openJobView')));
const JobsListView = Loader(lazy(() => import('../content/jobboard/components/jobsListView')));
const JobCreated = Loader(lazy(() => import('../content/jobboard/components/Success/index')));

const JobBoardRoutes = [
	{
		path: 'job-board',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				element: <JobBoard />,
			},
		],
	},
	{
		path: 'job-board/search',
		element: <OpenJobBoard />,
	},
	{
		path: 'view-applicants',
		element: (
			<Authenticated onlyAllow={[UserTypeLabels.BUSINESS, UserTypeLabels.BUSINESS_MANAGER]}>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':jobId',
				element: <Applicants />,
			},
		],
	},
	{
		path: 'view-job',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':jobId',
				element: <ViewJob />,
			},
		],
	},
	{
		path: 'record-video-interview',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':jobId',
				element: <RecordVideoInterview />,
			},
		],
	},
	{
		path: 'view-video-interview',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: ':jobId/:freelancerId',
				element: <ViewVideoInterview />,
			},
		],
	},
	{
		path: 'view-open-video-interview',
		element: <BaseLayout />,
		children: [
			{
				path: ':jobId/:freelancerId',
				element: <ViewVideoInterview publicMode />,
			},
		],
	},
	{
		path: 'view-open-job',
		element: <BaseLayout />,
		children: [
			{
				path: ':jobId',
				element: <ViewOpenJob />,
			},
		],
	},
	{
		path: 'job-created',
		element: (
			<Authenticated>
				<JobCreated />
			</Authenticated>
		),
	},
	{
		path: 'applied-jobs',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				element: <JobsListView showAppliedJobs />,
			},
		],
	},
	{
		path: 'expired-jobs',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				element: <JobsListView showExpiredJobs />,
			},
		],
	},
];

export default JobBoardRoutes;
