import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import {
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Avatar,
	Grid,
	Button,
	Switch,
	CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LaunchIcon from '@mui/icons-material/Launch';
import { Helmet } from 'react-helmet-async';
import { fetchPublicUserProfileInfoByUserId } from 'src/services/vendor';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { makeStyles } from '@material-ui/core/styles';
import JustLogoHeader from 'src/components/JustLogoHeader';
import { useSnackbar } from 'notistack';
import { copyToClipboard } from 'src/utils/copy';
import { CURRENT_URL } from 'src/mrkt365config';
import JobService from '../../services/jobService';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
	mainHeading: {
		marginBottom: 24,
	},
	jobTitle: {
		marginBottom: 16,
	},
	profileBox: {
		width: '80%',
		marginBottom: 4,
	},
	accordionStyle: {
		width: '80%',
		marginBottom: '20px',
	},
}));

const ViewVideoInterview = ({ publicMode = false }) => {
	const classes = useStyles();
	const { jobId, freelancerId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useAuth();
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(true);
	const [videoData, setVideoData] = useState(null);
	const [freelancerData, setFreelancerData] = useState(null);
	const [jobData, setJobData] = useState(null);
	const [expanded, setExpanded] = useState(0);
	const [videoInteriewIsPublic, setVideoInterviewIsPublic] = useState(null);
	const [enableCopyOpenLink, setEnableCopyOpenLink] = useState(false);
	const [errMsg, setErrMsg] = useState(null);
	const userIsFreelancer = user?.id === freelancerId;

	useEffect(() => {
		fetchVideoData(jobId, freelancerId);
	}, [jobId, freelancerId]);

	const refresh = () => {
		setIsLoading(true);
		fetchVideoData(jobId, freelancerId);
	};

	const fetchVideoData = async (jobId, freelancerId) => {
		setIsLoading(true);
		try {
			const [questionsData, answersData, freelancerProfile, job] = await Promise.all([
				JobService.getInterviewQuestions(jobId),
				publicMode
					? JobService.getAllPublicAnswers(jobId, freelancerId)
					: JobService.getAllAnswers(jobId, freelancerId),
				fetchPublicUserProfileInfoByUserId(freelancerId),
				JobService.getJob(jobId),
			]);
			setFreelancerData(freelancerProfile.data.query_result[0]);
			if (!answersData.success) {
				setErrMsg(answersData.error || 'Something went wrong please try again');
				return;
			}
			setVideoInterviewIsPublic(answersData.result.video_privacy_details.is_public);
			setEnableCopyOpenLink(answersData.result.video_privacy_details.videos_are_public_now);
			const videoData = questionsData.map((question) => {
				const answer = answersData.result.answers.find((answer) => answer.video_interview_question_id === question.id);
				return { ...question, answer: answer ? answer.video_url : '' };
			});
			setVideoData(videoData);
			setJobData(job.job.Job);
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	const handleAccordionChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const goBack = () => {
		if (location.key !== 'default') {
			navigate(-1);
		} else {
			navigate(`/view-${publicMode ? 'open-' : ''}job/` + jobId);
		}
	};

	const handleIsPublicChange = async (e, v) => {
		setVideoInterviewIsPublic(null);
		const response = await JobService.updateVideoAnsIsPublicFlag(jobId, v);
		setEnableCopyOpenLink(response.detail.videos_are_public_now);
		setVideoInterviewIsPublic(v);
	};

	const goToProject = () => {
		// add your navigation logic here
	};

	const copyOpenLink = () => {
		const linkToCopy = `${CURRENT_URL}view-open-video-interview/${jobId}/${freelancerId}`;
		copyToClipboard(linkToCopy);
		enqueueSnackbar(`Job #${jobId} video interview link is copied to clipboard!`, {
			variant: 'success',
			autoHideDuration: 5000,
			persist: false,
			anchorOrigin: {
				vertical: 'bottom',
				horizontal: 'left',
			},
		});
	};

	if ((!videoData || !freelancerData || !jobData) && !errMsg) {
		return <SuspenseLoader />;
	}

	const {
		profile_pic,
		first_name,
		last_name,
		headline,
		job_profile,
		experience_in_years = 'N/A',
		profile_id,
	} = freelancerData.profile;

	return (
		<>
			<Helmet>
				<title>
					Video Interview of {first_name} {last_name} for Job ID: {jobId}
				</title>
			</Helmet>

			{isLoading ? (
				<SuspenseLoader />
			) : (
				<>
					{publicMode ? <JustLogoHeader /> : null}
					{errMsg ? (
						<h3 style={{ textAlign: 'center' }}>{errMsg}</h3>
					) : (
						<>
							{!publicMode ? (
								<Grid display="flex" justifyContent="space-between" sx={{ mt: 2, mb: 0 }}>
									<Grid display="flex" sx={{ mr: 'auto', ml: 4, '@media screen and (max-width: 767px)': { ml: 1 } }}>
										<Button
											variant="outlined"
											sx={{ mr: 1 }}
											startIcon={<ArrowBackIosIcon />}
											size="small"
											onClick={goBack}
										>
											{publicMode ? 'Go to Job' : 'Back'}
										</Button>
									</Grid>
									<Grid mr={2}>
										{videoInteriewIsPublic ? (
											<Button
												size="small"
												color="success"
												startIcon={<ContentCopyIcon />}
												variant="outlined"
												onClick={copyOpenLink}
												disabled={!enableCopyOpenLink}
											>
												Copy Open Video Interview Link
											</Button>
										) : null}
										{userIsFreelancer ? (
											<>
												<Typography variant="p" sx={{ fontWeight: 'bold', ml: 1 }}>
													Make Video Interview Public:{' '}
												</Typography>
												{videoInteriewIsPublic !== null ? (
													<Switch checked={videoInteriewIsPublic} onChange={handleIsPublicChange} />
												) : (
													<CircularProgress size="1rem" sx={{ mx: 1 }} />
												)}
											</>
										) : null}
									</Grid>
								</Grid>
							) : null}
							<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding={2}>
								<Typography variant="h4" align="center" className={classes.mainHeading}>
									Video Interview of {first_name} {last_name} for Job ID: {jobId}
								</Typography>
								<Typography variant="h6" align="center" className={classes.jobTitle}>
									Job: {jobData.title}
								</Typography>
								<Box display="flex" flexDirection="column" alignItems="left" className={classes.profileBox}>
									<Avatar alt={`${first_name} ${last_name}`} src={profile_pic} style={{ width: 100, height: 100 }} />
									<Typography variant="h5" style={{ marginTop: 16 }}>
										{first_name} {last_name}
									</Typography>
									<Link to={`/profile/${profile_id}`}>
										Profile <LaunchIcon fontSize="small" />
									</Link>
									<Typography variant="subtitle1">{job_profile} </Typography>
									<Typography variant="body1">{experience_in_years} years of experience</Typography>
								</Box>

								{videoData.map((video, index) => (
									<Accordion
										key={index}
										expanded={expanded === index}
										onChange={handleAccordionChange(index)}
										className={classes.accordionStyle}
										style={{ marginBottom: expanded === index ? '20px' : '20px' }}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											<Typography variant="h6">
												Question {video.question_no}: {video.question}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											<Box display="flex" justifyContent="center" alignItems="center" height="50vh" width="100%">
												{video.answer ? (
													<div>
														<Typography variant="subtitle1">
															Answer by {first_name} {last_name}:
														</Typography>
														<video style={{ height: '320px' }} controls>
															<source src={video.answer} type="video/mp4" />
															Your browser does not support the video tag.
														</video>
													</div>
												) : (
													<Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
														The video you are trying to access is currently being uploaded. Please check back at a later
														time to view the video. Thank you for your patience.
														<br />
														<Button size="small" variant="outlined" onClick={refresh} sx={{ mt: 1 }}>
															Refresh
														</Button>
													</Typography>
												)}
											</Box>
										</AccordionDetails>
									</Accordion>
								))}
							</Box>
						</>
					)}
				</>
			)}
		</>
	);
};

export default ViewVideoInterview;
