import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function SuspenseLoader() {
    return (
        <Box
            sx={{
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: 999999999,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size={64} disableShrink thickness={3} />
        </Box>
    );
}
