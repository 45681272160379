import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Authenticated from 'src/components/Authenticated';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';

const Loader = (Component) => (props) =>
(
	<Suspense fallback={<SuspenseLoader />}>
		<Component {...props} />
	</Suspense>
);

// Pages
const ContactSupport = Loader(lazy(() => import('../content/contact-support/ContactSupport')));
const Dashboard = Loader(lazy(() => import('src/content/dashboard')));
const DownloadTracker = Loader(lazy(() => import('../content/download-tracker')));
// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const baseRoutes = [
	{
		path: '',
		element: <Authenticated>
			<Navigate to="/home" replace />,
		</Authenticated>
	},
	{
		path: '/dashboard',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				// element: <MrktDashboard />,
				element: <Dashboard />,
			},
		],
	},
	{
		path: '/home',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				// element: <MrktDashboard />,
				element: <Dashboard homeView />,
			},
		],
	},
	{
		path: 'download-tracker',
		element: (
			<Authenticated>
				<CollapsedSidebarLayout />
			</Authenticated>
		),
		children: [
			{
				path: '',
				element: (
					<Authenticated>
						<DownloadTracker />
					</Authenticated>
				),
			},
		],
	},

	{
		path: 'contact-support',
		element: <ContactSupport />,
	},
	{
		path: '*',
		element: <Status404 />,
	},
];

export default baseRoutes;
