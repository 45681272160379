import React from "react";
import { Grid } from "@mui/material";

export default function JustLogoHeader(props) {
    return (
        <Grid
            display="flex"
            justifyContent="space-between"
            sx={{ padding: "15px", backgroundColor: "white", boxShadow: 1, width: "100%" }}
        >
            <img
                src="/mrkt365logo_small.png"
                alt="Marketplace"
                style={{ objectFit: "contain", cursor: props.isBusiness && "pointer" }}
            />
        </Grid>
    );
}
