import React, { lazy } from "react";
import Authenticated from "src/components/Authenticated";
import CollapsedSidebarLayout from "src/layouts/CollapsedSidebarLayout";
import Loader from "src/components/Loader";
import { UserTypeLabels } from "src/constants";

// messenger
const Messenger = Loader(lazy(() => import("../content/messenger")));
const MessengerAlreadyOpenView = Loader(
    lazy(() => import("../content/messenger/components/messengerAlreadyOpenView"))
);

const MessengerRoutes = [
    {
        path: "messenger",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <Messenger />,
            },
        ],
    },
    {
        path: "messenger-opened-in-new-session",
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <MessengerAlreadyOpenView />,
            },
        ],
    },]

export default MessengerRoutes;