import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import VerifyEmail from 'src/content/verifyEmail/verifyEmail';
import SuspendedView from 'src/content/pages/Status/Suspended';
import Login from 'src/content/pages/Auth/Login/Cover';
import { getCurrentRelativeUrl } from 'src/helpers/generic';
import Status404 from 'src/content/pages/Status/Status404';
import OnboardingFlow from 'src/content/onboarding-flow';

const Authenticated = (props) => {
	const { children } = props;
	const auth = useAuth();
	const location = useLocation();
	const [requestedLocation, setRequestedLocation] = useState(null);

	if (!auth.isAuthenticated) {
		if (getCurrentRelativeUrl() !== requestedLocation) setRequestedLocation(getCurrentRelativeUrl());
		if (location.pathname === '/') return <OnboardingFlow />;
		return <Login />;
	}

	if (auth.user && !auth.user.is_verified) {
		if (getCurrentRelativeUrl() !== requestedLocation) setRequestedLocation(getCurrentRelativeUrl());
		return <VerifyEmail />;
	}

	if (auth.user && auth.user.is_suspended) return <SuspendedView />;

	if (props.onlyAllow && !props.onlyAllow.includes(auth.user.user_type)) return <Status404 />;

	if (requestedLocation && getCurrentRelativeUrl() !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
};

Authenticated.propTypes = {
	onlyAllow: PropTypes.array,
	children: PropTypes.node,
};

export default Authenticated;
