import React from "react";
import { Box, Button } from "@mui/material";

const ButtonWrapper = ({
    isSkipable = false,
    isPrevStep = false,
    isDisabled = false,
    nextCB,
    prevCB,
    skipCB,
    refs = {},
    variants = {},
}) => {
    const handleNextClick = () => {
        if (!nextCB) {
            throw new Error("nextCb prop is not provide");
        }
        nextCB();
    };
    const handlePrevClick = () => {
        if (!prevCB) {
            throw new Error("prevCB prop is not provide");
        }
        prevCB();
    };
    const handleSkipClick = () => {
        if (!skipCB) {
            throw new Error("skipCB prop is not provided");
        }
        skipCB();
    };
    return (
        <Box sx={{ mt: 3, maxWidth: "1000px", mx: "auto", display: "flex", justifyContent: "space-between" }}>
            <Box>
                {isPrevStep ? (
                    <Button variant="outlined" onClick={handlePrevClick}>
                        Prev Step
                    </Button>
                ) : null}
            </Box>
            <Box ml={2} display={"flex"}>
                <Button variant="contained" disabled={isDisabled} onClick={handleNextClick}>
                    Next Step
                </Button>
                {isSkipable ? (
                    <Button
                        variant={variants.skip ? variants.skip : "contained"}
                        onClick={handleSkipClick}
                        sx={{ ml: 2 }}
                        ref={refs.skip ? refs.skip : null}
                    >
                        Skip Step
                    </Button>
                ) : null}
            </Box>
        </Box>
    );
};

export default ButtonWrapper;
