import React from "react";
import ReactDOM from "react-dom";
import "src/utils/chart";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "src/hooks/useScrollTop";
import "nprogress/nprogress.css";
import { Provider } from "react-redux";
import store from "src/store";
import App from "src/App";
import { SidebarProvider } from "src/contexts/SidebarContext";
import * as serviceWorker from "src/serviceWorker";
import { AuthProvider } from "src/contexts/JWTAuthContext";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import axios from "axios";
import BrowserCommunicator from "./services/browserCommunicator";
import reportWebVitals from "./reportWebVitals";
import { trueProductionFlag, ClientToken } from "./mrkt365config";
import {appInsights}  from "./appInsights";

// the below header will be injected to every axios req
// TODO: Only add this in req made to MRKT365 APIs
axios.defaults.headers.common["client-token"] = ClientToken;

// var test_env_google_tag_id = "GTM-NDZZTLG";
// var test_env_google_tag_env_ga_auth = "rzhzSizGcZE_85PnEnK9Eg";
// var test_env_google_tag_env_ga_preview = "env-5";

// following object needs to be updated with real production ga_auth and ga_env

var tagManagerArgs = {};
appInsights.loadAppInsights();

// if (env === "development") {
//   tagManagerArgs["gtmId"] = test_env_google_tag_id;
//   tagManagerArgs["gtmAuth"] = test_env_google_tag_env_ga_auth;
//   tagManagerArgs["gtmPreview"] = test_env_google_tag_env_ga_preview;
// }
if (window.location.hostname.includes("mrkt365.com")) {
    tagManagerArgs["gtmId"] = "GTM-W3KBPT8";
    Sentry.init({
        dsn: "https://5fab0d99f2784ea5a3ebf01a5cd2851b@o1341363.ingest.sentry.io/6690302",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: trueProductionFlag
            ? "production"
            : window.location.host.includes("localhost")
            ? "development"
            : "staging",
    });
}

TagManager.initialize(tagManagerArgs);

// eslint-disable-next-line no-new
new BrowserCommunicator();

ReactDOM.render(
    <HelmetProvider>
        <Provider store={store}>
            <SidebarProvider>
                <BrowserRouter>
                    <ScrollTop />
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </SidebarProvider>
        </Provider>
    </HelmetProvider>,
    document.getElementById("root")
);

// eslint-disable-next-line no-console
reportWebVitals(console.log);
serviceWorker.unregister();
