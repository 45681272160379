import React, { lazy } from "react";
import Authenticated from "src/components/Authenticated";
import CollapsedSidebarLayout from "src/layouts/CollapsedSidebarLayout";
import Loader from "src/components/Loader";
import { UserTypeLabels } from "src/constants";

// manage team pages related urls
const ManageTeamHome = Loader(lazy(() => import("../content/manage-team/ManageTeamHome")));
const SendNewTeamMemberInvite = Loader(lazy(() => import("../content/manage-team/SendNewTeamMemberInvite")));


const ManageTeamRoutes = [

    // manage team
    {
        path: "manage-team",
        element: (
            <Authenticated onlyAllow={[UserTypeLabels.BUSINESS, UserTypeLabels.BUSINESS_MANAGER]}>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: "",
                element: <ManageTeamHome />,
            },
            {
                path: "invite-new-member",
                element: <SendNewTeamMemberInvite />,
            },
        ],
    },]

export default ManageTeamRoutes